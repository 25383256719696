// * Main imports
import React, { useEffect, useState, useRef, Suspense } from "react";
import { Canvas, useFrame, useLoader } from "react-three-fiber";
// import {
//   Sphere,
//   // Torus,
//   // TorusKnot,
//   //  Dodecahedron,
//   // Ring,
//   // Lathe,
//   // useGLTF,
//   // useFBX,
//   OrbitControls,
//   // StandardEffects,
// } from "@react-three/drei";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import Particles from "react-particles-js";
import { NextParticle } from "../functions/newNextParticle";
import scrollTo from "gatsby-plugin-smoothscroll";
import { graphql, navigate } from "gatsby";
import BackgroundImage from "gatsby-background-image";
// import Img from "gatsby-image";

// * Assets
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import undefinedLogoWhite from "../images/undefinedLogoWhite.png";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import "../styles/main.css";
// import blob1 from "../images/blobs/blob1.svg";
// import model from "../models/fishy.gltf";
// import butterfly from "../models/butterfly.fbx";
// import butterfly from "../models/butterfly.obj";

// * Components
import Contact from "../components/contact";

// styles
const pageStyles = {
  color: "#232129",
  // padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

function ParticleImage() {
  const size = useWindowSize();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    // Only draw the particle effect after the size is set properly
    if (counter === 1) {
      var nextParticle = new NextParticle(
        document.getElementById("particle-logo")
      );
      console.log("NExt particle is what?? ", nextParticle);
      nextParticle.particleGap = 2;
      nextParticle.noise = 30;
      nextParticle.mouseForce = 30;
      nextParticle.size = Math.max(window.innerWidth, window.innerHeight);
      nextParticle.colorize = false;
      nextParticle.tint = "#fff";
      nextParticle.minWidth = nextParticle.size;
      nextParticle.minHeight = nextParticle.size;
      nextParticle.maxWidth = nextParticle.size;
      nextParticle.maxHeight = nextParticle.size;

      var redraw = function () {
        nextParticle.initPosition = "none";
        nextParticle.initDirection = "none";
        nextParticle.fadePostion = "none";
        nextParticle.fadeDirection = "none";
        nextParticle.minWidth = nextParticle.size;
        nextParticle.minHeight = nextParticle.size;
        nextParticle.maxWidth = nextParticle.size;
        nextParticle.maxHeight = nextParticle.size;
        nextParticle.color = nextParticle.colorize ? nextParticle.tint : "";
        nextParticle.start();
      };

      nextParticle.width = size.width;
      nextParticle.height = size.height;
      // redraw();
      setCounter(2);
    } else if (counter === 0) {
      setCounter(1);
    }
  }, [size]);

  return (
    <div
      style={{
        "scroll-snap-align": "start",
        height: "100vh",
        width: "100vw",
        backgroundColor: "black",
        // backgroundColor: "#212121",
      }}
    >
      <img
        id="particle-logo"
        style={{ display: "none" }}
        className="next-particle"
        data-init-position="random"
        data-init-direction="random"
        data-min-width="750"
        data-min-height="750"
        // src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/23500/codepen-white.png"
        src={undefinedLogoWhite}
      />
    </div>
  );
}

function SideMenu() {
  const [hoverRef, isHovered] = useHover();

  return (
    <div className="side-menu-container">
      <div ref={hoverRef} className="side-menu">
        <div className="side-menu-list">
          <h5
            onClick={() => scrollTo("#section-services")}
            className="menu-item-services underline hoverable"
          >
            Services
          </h5>
          <div style={{ height: 20 }}></div>
          <h5
            onClick={() => scrollTo("#section-about")}
            className="menu-item-about underline hoverable"
          >
            About
          </h5>
          <div style={{ height: 20 }}></div>
          <h5
            onClick={() => scrollTo("#section-portfolio")}
            className="menu-item-portfolio underline hoverable"
          >
            Portfolio
          </h5>
          <div style={{ height: 20 }}></div>
          <h5
            onClick={() => scrollTo("#section-contact")}
            className="menu-item-contact underline hoverable"
          >
            Contact
          </h5>
        </div>
      </div>
      <div className="side-menu-small">
        <MoreVertIcon
          onClick={() => scrollTo("#section-services")}
          className="side-menu-small-icon"
        ></MoreVertIcon>
      </div>
    </div>
  );
}

// function BigSphere(props) {
//   // This reference will give us direct access to the mesh
//   const mesh = useRef();

//   // Set up state for the hovered and active state
//   const [hovered, setHover] = useState(false);
//   const [active, setActive] = useState(false);

//   // Rotate mesh every frame, this is outside of React without overhead
//   useFrame(() => {
//     mesh.current.rotation.x = mesh.current.rotation.y += 0.001;
//   });

//   // let GLTFish = useFBX(butterfly);
//   // return (
//   //   <Suspense fallback={null}>
//   //     <primitive object={GLTFish} dispose={null} />
//   //   </Suspense>
//   // );

//   return (
//     <Sphere
//       {...props}
//       ref={mesh}
//       // scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1]}
//       scale={[2, 2, 2]}
//       onClick={(event) => setActive(!active)}
//       onPointerOver={(event) => setHover(true)}
//       onPointerOut={(event) => setHover(false)}
//     >
//       {/* <boxBufferGeometry args={[1, 1, 1]} /> */}
//       <meshPhongMaterial color={true ? "#00ff00" : "orange"} wireframe />
//     </Sphere>
//   );
// }

const IndexPage = ({ data }) => {
  // const { innerWidth, innerHeight } = useWindowSize();
  const size = useWindowSize();

  useEffect(() => {
    // console.log("Data is: ", data);
    // window.addEventListener("mousemove", function (e) {
    //   document.getElementById("cursor").style.left = e.pageX + "px";
    //   document.getElementById("cursor").style.top = e.pageY + "px";
    // });
    const cursor = document.getElementById("cursor");
    let mouseX = 0;
    let mouseY = 0;

    let cursorX = 0;
    let cursorY = 0;

    let speed = 0.3;

    function animate() {
      let distX = mouseX - cursorX;
      let distY = mouseY - cursorY;

      cursorX = cursorX + distX * speed;
      cursorY = cursorY + distY * speed;

      cursor.style.left = cursorX + "px";
      cursor.style.top = cursorY + "px";

      requestAnimationFrame(animate);
    }
    animate();

    document.addEventListener("mousemove", function (event) {
      // mouseX = event.pageX;
      // mouseY = event.pageY;
      mouseX = event.clientX;
      mouseY = event.clientY;
    });

    var hoverItems = document.getElementsByClassName("hoverable");
    Array.from(hoverItems).forEach(function (element) {
      element.addEventListener("mouseenter", function (event) {
        cursor.classList.add("active");
      });
      element.addEventListener("mouseleave", function (event) {
        cursor.classList.remove("active");
      });
    });
  }, []);

  return (
    <React.Fragment>
      <div id="cursor-container"></div>
      <div id="cursor"></div>
      <main style={pageStyles}>
        <SideMenu />
        <ParticleImage />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ExpandMoreIcon
            onClick={() => scrollTo("#section-services")}
            className="next-page-icon hoverable"
          ></ExpandMoreIcon>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            background: "black",
            transform: "scaleY(1.02)",
            // background: "#303030",
          }}
        >
          <div className="wave" style={{ backgroundColor: "#212121" }}></div>
        </div>
        {/* Old background: #3498db */}
        <div
          id="section-services"
          className="section-container"
          style={{
            background: "#212121",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text" style={{ marginBottom: 20 }}>
              Services
            </h1>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="view-all-button hoverable">View All</div>
          </div> */}
          <div
            className="section-body"
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <div style={{ position: "relative", height: 0, width: "100%" }}>
              <div
                className="wave-container"
                style={{
                  // "scroll-snap-align": "start",
                  height: "30vh",
                  width: "100vw",
                  background: "transparent",
                  position: "absolute",
                  zIndex: 1000,
                  bottom: -250,
                  // transform: "translate(0%, 100%)",
                }}
              >
                <div
                  className="wave"
                  style={{
                    // backgroundColor: "#424242",
                    backgroundColor: "#212121",
                    transform: "scaleY(-1)",
                  }}
                ></div>
              </div>
            </div> */}
            {data.Services.edges.map((item, ind) => {
              const portfolioItem = item.node.data;
              console.log("ITem: ", item);
              const arrLength = data.Services.edges.length;
              const desiredNum = 4; //* This is the number of projects we want to show
              if (portfolioItem.Hero) {
                return (
                  <div className="section-portfolio-hero">
                    {ind === 0 ? (
                      <div
                        className="wave-container"
                        style={{
                          // "scroll-snap-align": "start",
                          height: "30vh",
                          width: "100vw",
                          background: "transparent",
                          position: "absolute",
                          zIndex: 1000,
                          top: -2,
                          // transform: "translate(0%, 100%)",
                        }}
                      >
                        <div
                          className="wave"
                          style={{
                            // backgroundColor: "#424242",
                            backgroundColor: "#212121",
                            transform: "scaleY(-1)",
                          }}
                        ></div>
                      </div>
                    ) : null}
                    <BackgroundImage
                      className="section-portfolio-hero-img"
                      style={{
                        bottom: ind + 1 === desiredNum ? -300 : 0,
                      }}
                      fluid={
                        portfolioItem.Hero?.localFiles[0].childImageSharp.fluid
                      }
                    ></BackgroundImage>
                    <div className="section-container-inner">
                      <h4 className="section-portfolio-hero-subtext">
                        {/* Maybe use this color? #00FF00 */}
                        <span style={{ color: "yellow" }}>0{ind + 1}</span>{" "}
                        {portfolioItem.ShortDescription}
                      </h4>
                      <div className="section-header">
                        <h1
                          className="section-header-text"
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            textAlign: "start",
                          }}
                        >
                          {portfolioItem.Name}
                        </h1>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 className="section-portfolio-hero-subtext">
                          <span style={{ color: "yellow" }}>Description</span>
                          <br></br>
                          {portfolioItem.Description}
                        </h4>
                      </div>
                      <div
                        className="section-portfolio-view-button hoverable"
                        onClick={() => {
                          navigate("/services/" + portfolioItem.Slug);
                        }}
                      >
                        View
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            {/* <div className="section-services-group">
              <div className="section-services-service">
                <div className="section-services-blob"></div>
                <h2 className="section-services-subheaders">
                  App Development{" "}
                </h2>
                <div className="section-services-contentbox">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p className="section-services-content-text">
                      We build in React Native, allowing for fast cross platform
                      support and easier maintainability.
                    </p>
                    <div className="section-services-learn-more hoverable">
                      Learn more
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-services-service">
                <div className="section-services-blob"></div>
                <h2 className="section-services-subheaders">Databases </h2>
                <div className="section-services-contentbox">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p className="section-services-content-text">
                      We're proficient in both SQL and NoSQL databases. We have
                      the most experience working with MongoDB, Firebase and
                      Postgres.
                    </p>
                    <div className="section-services-learn-more hoverable">
                      Learn more
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-services-service">
                <div className="section-services-blob"></div>
                <h2 className="section-services-subheaders">Integrations </h2>
                <div className="section-services-contentbox">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p className="section-services-content-text">
                      We have extensive experience integrating APIs between
                      different services. We can quickly link up pretty much
                      anything with an API.
                    </p>
                    <div className="section-services-learn-more hoverable">
                      Learn more
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-services-service">
                <div className="section-services-blob"></div>
                <h2 className="section-services-subheaders">
                  Custom Programming{" "}
                </h2>
                <div className="section-services-contentbox">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p className="section-services-content-text">
                      We can operate in many different languages and
                      environments, so if you need something done that's not
                      explicitly listed, just ask!
                    </p>
                    <div className="section-services-learn-more hoverable">
                      Learn more
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ExpandMoreIcon
              onClick={() => scrollTo("#section-about")}
              className="next-page-icon hoverable"
            ></ExpandMoreIcon>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div
            className="wave-container"
            style={{
              // "scroll-snap-align": "start",
              height: "30vh",
              width: "100vw",
              // background: "#424242",
            }}
          >
            <div className="wave" style={{ backgroundColor: "#414141" }}></div>
          </div>
        </div>
        {/* Old style: #9b59b6 */}
        <div
          id="section-about"
          className="section-container"
          style={{
            backgroundColor: "#414141",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <div className="section-header">
            <h1 className="section-header-text">About</h1>
          </div> */}
          {/* <img src={blob1} style={{ height: 200, width: 500 }}></img> */}
          <div
            className="section-body"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 20,
              position: "relative",
            }}
          >
            {/* <h2 className="section-about-text" style={{ width: "50%" }}>
              At Undefined, we're always looking for new challenges, trying to
              set our clients apart from the competition. We know how important
              it is to stay at the forefront of industries and we will do
              whatever it takes to keep our clients there.
            </h2> */}
            <div className="description-content" style={{}}>
              <div style={{ transform: "scale(-1,-1)", textAlign: "end" }}>
                <FormatQuoteIcon style={{ color: "white", fontSize: 90 }} />
              </div>
              <div className="section-header">
                <h1
                  className="section-header-text"
                  style={{ color: "white", margin: 0 }}
                >
                  At Undefined, we do whatever it takes to keep our clients on
                  the cutting edge
                </h1>
              </div>
              <div style={{ textAlign: "end" }}>
                <FormatQuoteIcon style={{ color: "white", fontSize: 90 }} />
              </div>
            </div>
            {/* <div style={{ position: "relative", height: "60vh", width: "50%" }}> */}
            {/* <Canvas
                className="hoverable"
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <Suspense fallback={null}>
                  <BigSphere></BigSphere>
                </Suspense>
                <OrbitControls />
              </Canvas> */}
            {/* </div> */}
          </div>
          {/* <Canvas>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <Box position={[-1.2, 0, 0]} />
            <Box position={[1.2, 0, 0]} />
          </Canvas> */}
          {/* <div className="section-about-blob"></div> */}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ExpandMoreIcon
              onClick={() => scrollTo("#section-portfolio")}
              className="next-page-icon hoverable"
            ></ExpandMoreIcon>
          </div>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            background: "#414141",
          }}
        >
          <div className="wave" style={{ backgroundColor: "#212121" }}></div>
        </div>
        {/* Old style: #e74c3c */}
        <div
          id="section-portfolio"
          className="section-container"
          style={{
            backgroundColor: "#212121",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text" style={{ marginBottom: 10 }}>
              Portfolio
            </h1>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="view-all-button hoverable">View All</div>
          </div> */}
          {/* <div style={{ position: "relative", height: 0, width: "100%" }}>
            <div
              className="wave-container"
              style={{
                // "scroll-snap-align": "start",
                height: "30vh",
                width: "100vw",
                background: "transparent",
                position: "absolute",
                zIndex: 1000,
                bottom: -250,
                // transform: "translate(0%, 100%)",
              }}
            >
              <div
                className="wave"
                style={{ backgroundColor: "#212121", transform: "scaleY(-1)" }}
              ></div>
            </div>
          </div> */}
          {data.Portfolio.edges.map((item, ind) => {
            const portfolioItem = item.node.data;
            console.log("ITem: ", item);
            const arrLength = data.Portfolio.edges.length;
            const desiredNum = 3; //* This is the number of projects we want to show
            if (portfolioItem.Hero) {
              return (
                <div className="section-portfolio-hero">
                  {ind === 0 ? (
                    <div
                      className="wave-container"
                      style={{
                        // "scroll-snap-align": "start",
                        height: "30vh",
                        width: "100vw",
                        background: "transparent",
                        position: "absolute",
                        zIndex: 1000,
                        top: -2,
                        // transform: "translate(0%, 100%)",
                      }}
                    >
                      <div
                        className="wave"
                        style={{
                          backgroundColor: "#212121",
                          transform: "scaleY(-1)",
                        }}
                      ></div>
                    </div>
                  ) : null}
                  <BackgroundImage
                    className="section-portfolio-hero-img"
                    style={{
                      bottom: ind + 1 === desiredNum ? -300 : 0,
                    }}
                    fluid={
                      portfolioItem.Hero?.localFiles[0].childImageSharp.fluid
                    }
                  ></BackgroundImage>
                  <div
                    // style={{ marginLeft: 50, marginBottom: 30, minWidth: 475 }}
                    className="section-container-inner"
                  >
                    <h4 className="section-portfolio-hero-subtext">
                      {/* Maybe use this color? #00FF00 */}
                      <span style={{ color: "yellow" }}>0{ind + 1}</span>{" "}
                      {portfolioItem.ShortDescription}
                    </h4>
                    <div className="section-header">
                      <h1
                        className="section-header-text"
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          textAlign: "start",
                        }}
                      >
                        {portfolioItem.Name}
                      </h1>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 className="section-portfolio-hero-subtext">
                        <span style={{ color: "yellow" }}>Platform</span>
                        <br></br>
                        {portfolioItem.ProjectType}
                      </h4>
                      <h4 className="section-portfolio-hero-subtext">
                        <span style={{ color: "yellow" }}>Year</span>
                        <br></br>
                        {portfolioItem.CompletedDate}
                      </h4>
                    </div>
                    <div
                      className="section-portfolio-view-button hoverable"
                      onClick={() => {
                        navigate("/projects/" + portfolioItem.Slug);
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>
              );
            }
          })}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ExpandMoreIcon
              onClick={() => scrollTo("#section-contact")}
              className="next-page-icon hoverable"
            ></ExpandMoreIcon>
          </div>
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            // background: "#e74c3c",
          }}
        >
          <div className="wave" style={{ backgroundColor: "#212121" }}></div>
        </div>
        {/* Old style: #2ecc71 */}
        <div
          id="section-contact"
          className="section-container"
          style={{
            backgroundColor: "#212121",
          }}
        >
          <div className="section-header">
            <h1 className="section-header-text">Get in touch</h1>
          </div>
          <div className="contact-main-text">
            <div style={{ width: 700 }}>
              <h3 className="section-contact-subheader-text">
                We're always looking to connect with potential clients and peers
                in the field. Feel free to either fill out the form or send us
                an email with any questions you have
              </h3>
            </div>
          </div>
          <Contact />
          {/* <form onSubmit={null}>
            <label>
              Name:
              <input type="text" value={null} onChange={null} />
            </label>
            <input type="submit" value="Submit" />
          </form> */}
          {/* <div className="section-header">
            <h1 className="section-header-text">Contact</h1>
          </div> */}
          {/* <div
            className="section-body"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="section-contact-box">
              <p className="section-contact-box-text">
                {" "}
                Hi my name is{" "}
                <span
                  className="section-contact-box-input hoverable"
                  role="textbox"
                  contentEditable="true"
                  onInput={(e) =>
                    console.log("Text inside div", e.currentTarget.textContent)
                  }
                >
                  Joe Shmoe
                </span>{" "}
                and I need a developer for{" "}
                <span
                  className="section-contact-box-input hoverable"
                  role="textbox"
                  contentEditable="true"
                  onInput={(e) =>
                    console.log("Text inside div", e.currentTarget.textContent)
                  }
                >
                  an app, website, custom widget, etc.
                </span>{" "}
                My budget is $
                <span
                  className="section-contact-box-input hoverable"
                  role="textbox"
                  contentEditable="true"
                  onInput={(e) =>
                    console.log("Text inside div", e.currentTarget.textContent)
                  }
                >
                  2000
                </span>{" "}
                and I need it done by{" "}
                <span
                  className="section-contact-box-input hoverable"
                  role="textbox"
                  contentEditable="true"
                  onInput={(e) =>
                    console.log("Text inside div", e.currentTarget.textContent)
                  }
                >
                  {new Date(
                    new Date().setDate(new Date().getDate() + 7)
                  ).toLocaleDateString()}
                </span>
                . Please contact me at{" "}
                <span
                  className="section-contact-box-input hoverable"
                  role="textbox"
                  contentEditable="true"
                  onInput={(e) =>
                    console.log("Text inside div", e.currentTarget.textContent)
                  }
                >
                  joe@shmoe.com
                </span>
              </p>
              <div className="section-contact-box-submit hoverable">Submit</div>
            </div>
          </div> */}
        </div>
        <div
          className="wave-container"
          style={{
            // "scroll-snap-align": "start",
            height: "30vh",
            width: "100vw",
            background: "#212121",
          }}
        >
          {/* <div className="wave" style={{ backgroundColor: "black" }}></div> */}
          <div className="wave" style={{ backgroundColor: "#303030" }}></div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default IndexPage;

// * Custom Hooks

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}

export const allTableQuery = graphql`
  query {
    Portfolio: allAirtable(
      sort: { fields: id }
      filter: { table: { eq: "SitePages" } }
    ) {
      edges {
        node {
          data {
            Logo {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            Hero {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
            Name
            Slug
            ShortDescription
            ProjectType
            CompletedDate
          }
        }
      }
    }
    Services: allAirtable(
      sort: { fields: data___Order }
      filter: { table: { eq: "SiteServices" } }
    ) {
      edges {
        node {
          data {
            Hero {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
            Name
            Description
            Slug
          }
        }
      }
    }
  }
`;

// export const allTableQuery = graphql`
//   query allTableQuery {
//     allAirtable(sort: { fields: id }, filter: { table: { eq: "SitePages" } }) {
//       edges {
//         node {
//           data {
//             Logo {
//               localFiles {
//                 childImageSharp {
//                   fluid(maxWidth: 1000) {
//                     ...GatsbyImageSharpFluid
//                   }
//                 }
//               }
//             }
//             Hero {
//               localFiles {
//                 childImageSharp {
//                   fluid(maxWidth: 1000) {
//                     ...GatsbyImageSharpFluid
//                   }
//                 }
//                 publicURL
//               }
//             }
//             Name
//             Slug
//             ShortDescription
//             ProjectType
//             CompletedDate
//           }
//         }
//       }
//     }
//   }
//   query serviceTableQuery {
//     allAirtable(
//       sort: { fields: id }
//       filter: { table: { eq: "SiteServices" } }
//     ) {
//       edges {
//         node {
//           data {
//             Hero {
//               localFiles {
//                 childImageSharp {
//                   fluid(maxWidth: 1000) {
//                     ...GatsbyImageSharpFluid
//                   }
//                 }
//                 publicURL
//               }
//             }
//             Name
//             Description
//           }
//         }
//       }
//     }
//   }
// `;
